<template>
  <v-container
    id="dataexport"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('mpref.dset-dwnl') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Include Datasets for download, open and close card -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Dashboard Datasets Info -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-4">
        <!-- First column -->
        <v-col
          cols="12"
          md="9"
        >
          <v-card-text
            v-for="(dset, i) in dDatasets"
            :key="i"
            class="py-0"
            fluid
          >
            <v-checkbox
              v-model="dset.selected"
              class="mt-2"
              :label="translateMe(dset.label)"
              :prepend-icon="dset.icon"
              :readonly="dset.readonly"
              hide-details
              :disabled="myRole() === 'viewer' || loadingdata"
              @click="dDatasetSelected({ item: i, state: dset.selected, pack: dset.pack})"
            />
            <!-- <div
              class="text--disabled"
            >
              {{ dset }}
            </div> -->
          </v-card-text>
        </v-col>
        <!-- Second column -->
        <v-col
          cols="12"
          md="3"
        >
          <v-card-text>
            <v-row>
              <v-spacer />
              <!-- Restore button -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="success"
                    class="py-8"
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogRestore = true"
                  >
                    <v-icon left>
                      mdi-restore
                    </v-icon>
                    {{ $t('mpref.dset-restore') }}
                  </v-btn>
                </template>
                <span>{{ $t('mpref.dset-restore-tooltip') }}</span>
              </v-tooltip>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row class="pb-4 px-8">
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
            class="py-0 my-0"
            hide-details
          />
        </v-row>
      </v-card-actions>
    </v-card>
    <div
      v-if="loadingdata"
      class="loading"
    >
      <v-progress-linear
        indeterminate
        color="info"
      />
      <!-- ...{{ $t('tooltip.loading') }} -->
    </div>

    <!-- Activate it while getting data -->
    <!-- <v-overlay :value="loadingdata">
      <v-progress-circular

        indeterminate
        size="64"
      />
    </v-overlay> -->

    <!-- Download Datasets -->
    <v-row
      class="d-flex justify-space-between mt-8"
    >
      <base-material-card
        v-for="(type, i) in finalDataset"
        :key="i"
        v-model="finalDataset"
        :color="type.color"
        :icon="type.icon"
        width="270"
        inline
        class="mx-4"
        hover-reveal
      >
        <template v-slot:reveal-actions>
          <div class="d-flex justify-space-between mb-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="mx-3"
                  v-bind="attrs"
                  icon
                  dark
                  :disabled="type.loading"
                  v-on="on"
                  @click="genDataset(type.pack)"
                >
                  <v-icon
                    size="20"
                    color="indigo"
                  >
                    mdi-folder-refresh
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dashboard.ddset-gen') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <div class="display-2 font-weight-light grey--text">
                  <v-btn
                    :download="syncLinks.stata"
                    class="mx-1"
                    v-bind="attrs"
                    icon
                    dark
                    :loading="type.loading"
                    color="primary"
                    v-on="on"
                    @click="dataLinks(type)"
                  >
                    <v-icon
                      color="grey darken-1"
                      large
                    >
                      mdi-folder-download
                    </v-icon>
                  </v-btn>{{ type.title }}
                </div>
              </template>
              <span>{{ $t('common.download') }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:actions>
          <!-- {{ dateDiffInDays(type.syncdate) }} -->
          <span
            v-if="type.syncdate !== '' && dateDiffInDays(type.syncdate) < 24"
            class="caption grey--text font-weight-light"
          >
            <v-icon
              class="mr-1 mt-0"
              small
            >
              mdi-clock-outline
            </v-icon>
            {{ $t('dashboard.generated') }}
            <timeago
              :datetime="type.syncdate"
              :locale="$i18n.locale"
              :auto-update="60"
            />
          </span>
          <span
            v-else
            class="caption grey--text font-weight-light"
          >
            <v-icon
              class="mr-1 mt-0"
              small
              @click="genDataset(type.pack)"
            >
              mdi-folder-refresh-outline
            </v-icon>
            {{ $t('dashboard.ddset-gen') }}
          </span>
        </template>
      </base-material-card>
    </v-row>

    <!-- DIALOGS -->

    <!-- Restore Dataset Dialog -->
    <v-dialog
      v-model="dialogRestore"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('mpref.dset-restore') }}
        </v-card-title>
        <v-card-text>
          {{ $t('mpref.dset-restore-note') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="grey darken-1"
            @click="dialogRestore = false"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="restoreDataset()"
          >
            {{ $t('common.restore') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dashbord Blocks Select variable Dialog -->
    <v-dialog
      v-model="dialogGis"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('mpref.select-var') }}
        </v-card-title>
        <v-card-text>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(gisSelected)">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('common.table')"
                rules="required"
              >
                <v-select
                  v-model="selectedSection"
                  :items="dataSet"
                  :error-messages="errors"
                  item-text="label[0]"
                  item-value="table[0]"
                  prepend-icon="mdi-table"
                  :label="$t('mpref.select-tbl')"
                  return-object
                  validate-on-blur
                  @change="sectionSelected"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="t.title"
                rules="required"
              >
                <!-- Used for collecting question from select list -->
                <v-autocomplete
                  v-model="lat"
                  :items="questions"
                  :error-messages="errors"
                  item-text="label"
                  item-value="id"
                  prepend-icon="mdi-map-marker-plus"
                  :label="$t('mpref.lat')"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="t.title"
                rules="required"
              >
                <!-- Used for collecting question from select list -->
                <v-autocomplete
                  v-model="lon"
                  :items="questions"
                  :error-messages="errors"
                  item-text="label"
                  item-value="id"
                  prepend-icon="mdi-map-marker-plus"
                  :label="$t('mpref.lon')"
                />
              </validation-provider>

              <v-row>
                <v-btn
                  text
                  @click="gisCancelled('aahh')"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  type="submit"
                >
                  {{ $t('common.save') }}
                </v-btn>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- New SnackkBar -->
    <v-snackbar
      v-model="snackBar.visible"
      auto-height
      :color="snackBar.color"
      :multi-line="snackBar.mode === 'multi-line'"
      :timeout="snackBar.timeout"
      :top="snackBar.position === 'top'"
    >
      <v-row>
        <v-icon
          class="pr-3"
          dark
          large
        >
          {{ snackBar.icon }}
        </v-icon>
        <v-col>
          <div>
            <strong>{{ snackBar.title }}</strong>
          </div>
          <div>{{ snackBar.text }}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          @click="snackBar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import VueTimeago from 'vue-timeago'
  import axios from 'axios'

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  export default {
    name: 'DataExport',

    components: {},

    mixins: [
      userAccess,
      dashboard,
    ],

    data () {
      return {
        helptoolbar: true,

        dDatasets: [
          // { pack: 'csv', color: 'success', syncdate: '', link: '', loading: false, icon: 'mdi-table', title: 'CSV', label: 'mpref.dset-csv', selected: false },
          { pack: 'excel', color: 'info', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'Excel', label: 'mpref.dset-excel', selected: false },
          { pack: 'pexcel', color: 'blue', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'pExcel', label: 'mpref.dset-pexcel', selected: false },
          { pack: 'stata', color: 'warning', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'Stata', label: 'mpref.dset-stata', selected: false },
          { pack: 'rds', color: 'indigo', syncdate: '', link: '', loading: false, icon: 'mdi-alpha-r-circle', title: 'Rds', label: 'mpref.dset-r', selected: false },
          { pack: 'spss', color: 'primary', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SPSS', label: 'mpref.dset-spss', selected: false },
          { pack: 'sas', color: 'error', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SAS', label: 'mpref.dset-sas', selected: false },
          { pack: 'arcgis', color: 'secondary', syncdate: '', link: '', loading: false, icon: 'mdi-earth', title: 'ArcGIS', label: 'mpref.dset-arcgis', selected: false },
        ],

        dialogGis: false,
        selectedSection: '',
        questions: [],
        lat: '',
        lon: '',
        selectedDataset: {},

        syncLinks: {},
        syncDate: '',

        loading: false,
        loadingdata: true,

        dialogRestore: false,

        // notification about sync
        snackBar: {
          color: 'success',
          icon: 'mdi-information',
          mode: 'multi-line',
          position: 'top',
          timeout: 7500,
          title: 'Success',
          text: 'That worked, hoorah.',
          visible: false,
        },

        // check submisions
        polling: null,

        // Default no blocks
        hasBlock: false,

        // Dialogs
        dialogBlocks: false,
      }
    },

    computed: {
      // Return only selected types of datasets
      finalDataset () {
        // console.log(this.dDatasets[0].syncdate)
        // console.log(Date() - this.dDatasets[0].syncdate)
        return this.dDatasets.filter(function (ds) {
          return ds.selected
        })
      },

      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dDatasetInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dDatasetInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },
    },

    created () {
      const main = this.$store.state.main
      // Fetching After Navigation
      // Fetch the data when the view is created and the data is already being observed
      // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation

      // Periodically check submissions if form state is not closed
      if (main.af.odksettings.state !== 'closed') {
        // function from mixin dashboard.js
        this.checkSubmissions()
      }

      // console.log('created')
      this.getdDataset()
    },

    mounted () {
      const main = this.$store.state.main

      // If this a first visit get dashboard blocks from ocpu
      if (main.dsb.calls === false) {
        // // Get Table list from ocpu
        // this.getTableList()

        // // Get block info from ocpu dBlocks - dashboarBlocks; dDataset - dashboardDataset; fsettings - Form settings
        // const blocks = ['fsettings', 'dBlocks']
        // blocks.forEach(type => {
        //   // Using function in mixin dashboard.js
        //   this.dBlockGet(type)
        // })
      }
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dDatasetInfo

      // Get if any new submissions (function from mixin dashboard.js)
      this.getSubmissions()
    },

    beforeDestroy () {
      // We need clear interval as we run checkSubmissions()
      clearInterval(this.polling)
    },

    methods: {
      translateMe (val) {
        return this.$t(val)
      },

      // Update this.dDatasets value (selected) to true according to packs array list
      async getdDataset () {
        // Wait while we get data from ocpu
        await this.dBlockGet('dDataset')

        // selected datasets as array
        const selectedDset = this.$store.state.main.dsb.dDataset.filter(x => x.selected === true).map(x => x.pack)

        // Update this.dDatasets
        this.dDatasets.map(dSet => {
          if (selectedDset.includes(dSet.pack)) {
            dSet.selected = true

            // get Syncronization data for each selected dataset
            const dsname = dSet.title
            // console.log(dsname)
            this.getdsSyncData(dsname.toLowerCase())
          }
        })
        this.loadingdata = false
      },

      // When one of Dataset checkboxes are selected / unselected
      dDatasetSelected (prop) {
        // console.log(prop)
        this.selectedDset = prop
        if (prop.pack === 'arcgis' && prop.state === true) {
          this.dialogGis = true
        } else {
          // Mutate state for other files, ArcGis is mutated in gisSelected()
          this.$store.commit('main/setdDatasetState', prop)
          // update ocpu
          this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
        }
      },

      sectionSelected (section) {
        // console.log(selectedTable.column)
        this.questions = section.column
      },

      // When ArcGis file selection is cancelled
      gisCancelled () {
        this.dialogGis = false
        // unselect it in dDatasets
        const gis = this.dDatasets.filter(x => x.pack === 'arcgis')
        gis[0].selected = false
        // Mutate state
        this.selectedDset.selected = false
        this.$store.commit('main/setdDatasetState', this.selectedDset)
        // update ocpu
        this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
      },

      // When gis Dataset checkboxes is selected
      gisSelected () {
        this.dialogGis = false
        // Mutate state for ArcGis
        this.$store.commit('main/setdDatasetState', this.selectedDset)
        // update ocpu
        this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
      },

      restoreDataset () {
        this.loading = true
        this.dialogRestore = false
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const orgid = this.$store.state.bend.organization.orgid
        const prjid = this.$store.state.main.ap.prjid
        const formid = this.$store.state.main.afid
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/restorestables/json`, {
              orgid: orgid,
              prjid: prjid,
              formid: formid,
            })
            .then(response => {
              this.loading_mtools = false
              // console.log(response)
              if (response.status === 201) {
                this.snackBar = {
                  color: 'success',
                  icon: 'mdi-check-circle',
                  mode: 'multi-line',
                  position: 'bottom',
                  timeout: 6000,
                  title: `${this.$t('common.success')}`,
                  text: `${this.$t('mpref.dset-restore-success')}`,
                  visible: true,
                }
                resolve(response)
              }
              this.loading = false
            })
            .catch(error => {
              this.snackBar = {
                color: 'error',
                icon: 'mdi-alert-circle',
                mode: 'multi-line',
                position: 'top',
                timeout: 6000,
                title: `${this.$t('common.error')}`,
                text: `${this.$t('mpref.dset-restore-error')}`,
                visible: true,
              }
              this.loading = false
              // console.log(error)
              reject(error)
            })
        })
      },

      dateDiffInDays (syncdate) {
        const nowdate = new Date()
        const sncdate = new Date(syncdate)
        const _MS_PER_DAY = 1000 * 60 * 60
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate())
        const utc2 = Date.UTC(sncdate.getFullYear(), sncdate.getMonth(), sncdate.getDate())
        const diff = Math.floor((utc1 - utc2) / _MS_PER_DAY)
        // console.log(diff)

        return diff
      },

      // Generate new dataset and save sync data to ocpu
      async genDataset (pack, i) {
        // console.log(pack)
        // console.log(this.finalDataset)
        // console.log(this.dDatasets)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // const dsname = pack.substr(2)
        const pos = this.dDatasets.map(function (e) { return e.title.toLowerCase() }).indexOf(pack)
        // console.log(pos)
        this.dDatasets[pos].loading = true

        const main = this.$store.state.main
        const fpath = `${main.orgid}/${main.ap.prjid}/${main.af.odksettings.id}`

        // STEP 2. Save Sync details into ocpu's file type folder
        const postSyncData = (syncData) => {
          // console.log('postSyncData da')
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocsavesyncdata/json`, { syncdata: syncData, fpath: fpath, filetype: pack })
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  return resolve()
                }
              })
              .catch(error => {
                // console.log(error)
                // if (error.response.status !== 201) {
                this.snackBar = {
                  color: 'error',
                  icon: 'mdi-alert-circle',
                  mode: 'multi-line',
                  position: 'top',
                  timeout: 4500,
                  title: `${this.$t('common.error')}`,
                  text: 'Something is gone wrong, sorry. Please, inform UDACAPI Admin',
                  visible: true,
                }
                //   reject(error)
                // }
                reject(error)
              })
          })
        }

        const props = { orgid: main.orgid, prjid: main.ap.prjid, formid: main.af.odksettings.id, filetype: pack.toLowerCase() }
        // STEP 1. generate new dataset
        return new Promise((resolve, reject) => {
          // console.log(props)
          this.$httpOcpu.post(`/${ocpuPack}/R/oczipfiles/json`, props)
            .then(async response => {
              // console.log(response)
              // console.log(response.headers.location)
              const d = new Date()
              // const syncDate = d.toISOString().slice(0, 19) + '+00:00'

              const syncDate = d.toISOString().slice(0, 19) + 'Z'
              const link = `${response.headers.location}files/${response.data[0]}`
              const syncData = { link: link, syncDate: syncDate }

              // post Syncronization Data to ocpu
              // STEP 1.2 run postSyncData
              await postSyncData(JSON.stringify(syncData))
              this.getdsSyncData(pack)
            })
            .catch(error => {
              // console.log(error)
              if (error.response.status !== 201) {
                this.snackBar = {
                  color: 'error',
                  icon: 'mdi-alert-circle',
                  mode: 'multi-line',
                  position: 'top',
                  timeout: 6000,
                  title: 'Error during generation of file',
                  text: 'Something is gone wrong, sorry. Please, inform UDACAPI Admin',
                  visible: true,
                }
                this.dDatasets[pos].loading = false
                reject(error)
              }
              // reject(error)
            })
        })
      },

      // Get Sync details and update
      getdsSyncData (pack) {
        // console.log(dsname)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const orgid = this.$store.state.bend.organization.orgid
        const prjid = this.$store.state.main.ap.prjid
        const formid = this.$store.state.main.af.odksettings.id
        const fpath = `${orgid}/${prjid}/${formid}`
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetsyncdata/json`, { fpath: fpath, filetype: pack })
            .then(response => {
              // console.log(response)
              if (response.data.length > 0) {
                const syncData = JSON.parse(response.data[0])
                const pos = this.dDatasets.map(function (e) { return e.title.toLowerCase() }).indexOf(pack)
                this.dDatasets[pos].loading = false
                this.dDatasets[pos].syncdate = syncData.syncDate
                this.dDatasets[pos].link = syncData.link
              }
            })
        })
      },

      // Constructs download link for datasets
      dataLinks (dDataset) {
        // console.log(dDataset)
        if (dDataset.link.length > 0) {
          // console.log(dDataset)
          const sLink = dDataset.link
          const fileName = sLink.substring(sLink.lastIndexOf('/') + 1)
          // console.log(fileName)
          axios({
            url: sLink,
            method: 'GET',
            responseType: 'blob',
          }).then(response => {
            // console.log(response)
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
            window.URL.revokeObjectURL(fileURL)
          }).catch(error => {
            // console.log(error.response.status)
            if (error.response.status === 400) {
              this.snackBar = {
                color: 'error',
                icon: 'mdi-alert-circle',
                mode: 'multi-line',
                position: 'top',
                timeout: 6000,
                title: 'Error',
                text: 'No file existing. Please, generate a new file.',
                visible: true,
              }
            }
          })
        }
      },
    },
  }
</script>

<style lang="sass">
  @import "~leaflet/dist/leaflet.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

  #coloured-line
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: #00cae3 !important

    .ct-series-b .ct-bar
      stroke: #f44336 !important

  #progressPie
    .ct-series-a .ct-slice-pie
      fill: #659D32 !important

    .ct-series-b .ct-slice-pie
      fill: #FF8C00 !important
</style>
